import React from 'react';
import { Helmet } from 'react-helmet';
import { Button } from 'fogg/ui';

import Layout from 'components/Layout';
import Container from 'components/Container';
import Jumbotron from 'components/Jumbotron';

const ContactThankYouPage = () => {
  return (
    <Layout>
      <Helmet
        bodyAttributes={{ class: 'page-contact-thank-you page-thank-you' }}
      >
        <title>Thank you!</title>
      </Helmet>
      <Jumbotron>
        <h1>Stay tuned.</h1>
      </Jumbotron>
      <Container>
        <p className="align-center">
          We&apos;ll reach out as soon as we can. In the meantime, read more
          about FilmDrop.
        </p>
        <p className="align-center">
          <Button to="/">Learn More About FilmDrop</Button>
        </p>
      </Container>
    </Layout>
  );
};

export default ContactThankYouPage;
